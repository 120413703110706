
import '../Backgrounds.css'
import './About.css';
import MainHeader from '../components/MainHeader';
import headshot from '../assets/Headshot.jpg'
import Footer from '../components/Footer';
import { Link } from "react-router-dom";
import { useEffect } from 'react';


const About = () => {


    useEffect(() => {
        window.scrollTo(0,0)
      }, []);

    return (
        <div className='aboutMeBackground'>
            <MainHeader/>

            

            <div className='mainContentWrapper'>

               

                <div className='aboutMeContentWrapper'>

                    <div className='solidLine'></div>

                    <div className='aboutMeContent'>
                        <img src = {headshot}  className='headshot' />
                        <div className='aboutMeText'>
                            <p>
                            Will Turner is an award winning Bristol based composer, working primarily in Film, TV and Games. His scores have premiered at Cannes, Aesthetica, Visions Du Reel, Jackson Wild, and Wildscreen; with broadcast work airing on Roku, Channel 4, BBC 2, Hulu and Disney+. 
                           </p>
                           <br></br>
                           <p>
                           With a focus on innovative story-telling, Will moved to Bristol primarily with a view to contribute to the thriving wildlife film-making scene in the city. Over the course of the last 5 years, Will produced the scores for 13 different Wildlife projects, including Life On The Rocks - which went on to beat out Perfect Planet to ‘best in festival’ at the International’s Wildlife Film Festival in 2021. Life On The Rocks also secured a Panda nomination at Wildscreen, and a host of other festival wins including at Jackson Wild and Naturvision. Since 2021 Will has written the scores for four broadcast series, culminating in the 6 x 1 hour BBC NHU series Big Cats 24/7, which aired in the Sunday 8pm slot on BBC 2. 
                           </p>
                           <br></br>
                           <p>
                           Alongside Natural History, Will has worked across the genres contributing to over 40 other projects. This has included a documentary nomination at the Griersons, work showing on Channel 4, adaptive video game scoring projects and a TV drama pilot in production later on this year. 
                           </p>
                           <br></br>
                           <p>
                           Will graduated with a Masters in Composition from the National Film And Television School in 2019, after receiving the Christie Prize for the most promising student at the school, since then he has also secured the Francis Chagrin award for innovation in composing. 
                           </p>
                           <br></br>
                           <p>
                           Will is originally a classically trained pianist and saxophone player. With this basis he developed a solid understanding of traditional form and orchestration, the foundation of which has proved invaluable throughout his scoring career. However, after spending a number of years in a variety of Punk, Rock and Electronic bands, he expanded his vocabulary and honed his production skills allowing him to incorporate a much more modern sound into his music. This allowed him to provide an alternative, contemporary approach to scoring that can be heard throughout his projects. 
                           </p>
                           <br></br>
                           <p>
                           As a film composer what Will privileges above all else is collaboration and of course - story. 
                           </p>
                           <br></br>
                           <div className='portfolioClickThroughContainer'>
                                <Link to='/portfolio'>
                                    <button className="button4">
                                    FULL PORTFOLIO
                                    </button>
                                </Link>
                            </div>
                        </div>




                    </div>

                    <div className='solidLine'></div>

                    <Footer/>

                
                </div>
                
            </div>
        </div>

    )
}
export default About; 