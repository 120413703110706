import Projects from "../components/ProjectPage.js";
import { useParams } from "react-router-dom";
import { useState,useRef,useEffect } from "react";

import portfolioSlideData from '../databases/portfolioSlideData.json'

import { CSSTransition } from 'react-transition-group';
import PortfolioSlideData from '../databases/portfolioSlideData.json'

const ProjectWrapper = (props) => {
    const nodeRef = useRef(null);
    let {projectID} = useParams();
    console.log(portfolioSlideData[0].tracks)
    const [projectNumber,setProjectNumber] = useState(parseInt(projectID));
    const [videoMounted,setVideoMounted] = useState(true);
    const slideCount = PortfolioSlideData.length;
    console.log(slideCount);
    
    // const [projectID,setProjectId] = useState(useParams());
    // let {slideData} = portfolioSlideData[projectID];
    // console.log(projectID)
    // console.log(portfolioSlideData[projectID])

    const prevPage = () => {

        if (projectNumber == 0)
        {
            setVideoMounted(false);
            console.log(slideCount);
            setTimeout(() => {setProjectNumber(slideCount-1)},305)
            setTimeout(() => {setVideoMounted(true)}, 305)  

        }  else {


            setVideoMounted(false);
            console.log(projectNumber);
            setTimeout(() => {setProjectNumber(projectNumber - 1)},305)
            setTimeout(() => {setVideoMounted(true)}, 305)  
            
        
        }
    }


    const nextPage = () => {
        if (projectNumber == slideCount - 1)
        {
            setVideoMounted(false);
            setTimeout(() => {setProjectNumber(0)},305)
            setTimeout(() => {setVideoMounted(true)}, 305)  

        }  else {


            setVideoMounted(false);
            console.log(projectNumber);
            setTimeout(() => {setProjectNumber(projectNumber + 1)},305)
            setTimeout(() => {setVideoMounted(true)}, 305)  
            
        
        }
    }

    return (


        <CSSTransition nodeRef={nodeRef} in={videoMounted} timeout={200} classNames="my-node" unmountOnExit>

            
        <div ref={nodeRef}>


            <Projects
            videoUrl = {portfolioSlideData[projectNumber].videoURL}
            image1Url = {portfolioSlideData[projectNumber].image1URL}
            image2Url = {portfolioSlideData[projectNumber].image2URL}
            image3Url = {portfolioSlideData[projectNumber].image3URL}
            audioPlayerPath = {portfolioSlideData[projectNumber].audioPlayerPath}
            directedBy = {<div> {portfolioSlideData[projectNumber].directedBy} </div>}
            projectPara1 = {<div>    {portfolioSlideData[projectNumber].projectPara1}</div>}
            projectPara2 = {<div>    {portfolioSlideData[projectNumber].projectPara2}</div>}
            projectPara3 = {<div>    {portfolioSlideData[projectNumber].projectPara3}</div>}
            projectTitle = {portfolioSlideData[projectNumber].title}
            trackArray = {portfolioSlideData[projectNumber].tracks}
            nextPage = {nextPage}
            prevPage = {prevPage}
            multitrack = {portfolioSlideData[projectNumber].multitrack}
            
            />
        </div>
        </CSSTransition>


    )
}
export default ProjectWrapper; 