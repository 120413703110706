import React from "react";

// import videoBg from '../assets/MamasSeason1BackgroundVideo.mp4'

const VideoWithOverlay = (props) => {
    const videoUrl = props.videoUrl
    
    return(
    <div className="videoContainer">
        <div className = 'videoShadow' />
        <div className=  "hiddenOverflow">

            <video src={videoUrl} className ='video' autoPlay loop muted playsInline/> 
        </div>
        <div className = 'videoEdgeShadow' />
        <div className = 'videoOverlay' />
    </div>
    )

}

export default VideoWithOverlay