import { useState,useRef } from "react";
import MenuDropdownCSS from './MenuDropdown.module.css';
import {GiHamburgerMenu} from 'react-icons/gi'
import OpenDropdown from "./OpenDropdown";
import {RxCross1} from 'react-icons/rx'
import './DropdownTransition.css';
import { CSSTransition } from 'react-transition-group';


const MenuDropdown = () => {

    const [dropdownIsOpen,openDropdown] = useState(false);
    const nodeRef = useRef(null);
    return (
      <div>

            
        <GiHamburgerMenu  className={MenuDropdownCSS.menuDropdown} onClick={()=>{
                openDropdown(true);
            }}
            >
            </GiHamburgerMenu>

        <CSSTransition nodeRef={nodeRef} in={dropdownIsOpen} timeout={200} classNames="my-node" unmountOnExit>

            
          <div ref={nodeRef}>
            <OpenDropdown openDropdown={openDropdown}/>
          </div>
        </CSSTransition>
      </div>
    );
}

export default MenuDropdown;