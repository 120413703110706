
import '../Backgrounds.css'
import Gallery from "../components/Gallery.js";
import '../App.css';
import './Portfolio.css'
import MainHeader from '../components/MainHeader';
import Footer from '../components/Footer';
import { useEffect } from 'react';

const Portfolio = () => {

    useEffect(() => {
        window.scrollTo(0,0)
      }, []);
    
    return (

        <div className="portfolioBackground">
            <MainHeader/>
            <div className='mainContentWrapper'>
                <div className='portfolioContainer'>
                    <div className='solidLine'>_</div>
                    <Gallery></Gallery>
                    <div className='solidLine'>_</div>
                </div>

                

                <Footer/>
            </div>
            
        </div>

    )
}
export default Portfolio; 