
import '../Backgrounds.css'
import './ProjectPage.css'
import '../App.css'
import VideoBackground from './ProjectPageVideoBackground';
import MainHeader from './MainHeader';
import AudioPlayer from './audioPlayer/AudioPlayer';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import Slider from './Carousel';
import {IoIosArrowBack, IoIosArrowForward} from 'react-icons/io'
import { useEffect } from 'react';


const ProjectPage = (props) => {

    useEffect(() => {
        window.scrollTo(0,0)
      }, []);



    const videoUrl = props.videoUrl
    const image1Url = props.image1Url
    const image2Url = props.image2Url
    const image3Url = props.image3Url
    const audioPlayerPath = props.audioPlayerPath
    const directedBy =  props.directedBy
    const projectTitle = props.projectTitle
    const projectPara1 = props.projectPara1
    const projectPara2 = props.projectPara2
    const projectPara3 = props.projectPara3
    const prevIndex = (props.index - 1)
    const nextIndex = (props.index + 1)

    return (
        <div className='projectsBackground'>
            
                <MainHeader/>
                
                <div className="portfolioBackground">
                    
                </div>


                
                <div className='projectGridContainer'>

                    
                    <div className = 'projectGrid'>

                        

                        <div className='mainBodyContainer'>
                            <div className='solidLine'> _ </div>

                            <div className = 'projectTitle'> PROJECT : {projectTitle}</div>






                        </div>

                        <div className='imagePlayerCombiFrame'>
                            <div className='videoFrame'>
                                <VideoBackground 
                                videoUrl = {videoUrl}
                                />
                             </div>
                            

                            <div className='playerFrame'>
                                <div className = "player">
                                    <AudioPlayer  multiTrack = {props.multitrack} trackArray = {props.trackArray}  folderPath = {audioPlayerPath} onTrackChange = {() => {}}/>
                                </div>
                            </div>
                        </div>


                        <div className='mainBodyContainer'>
                            <div className = 'projectTitleWrapper'>


                                <div className='leftButtonWrapper'>
                                    <IoIosArrowBack className='arrow 'onClick = {() => {props.prevPage()}}/>
                                    <button className="navButton" onClick = {() => {props.prevPage()}}
                                            >
                                        PREV

                                    </button>
                                </div>


                                <div className = 'navTitle'> {projectTitle} </div>

                                <div className='rightButtonWrapper'>
                                
                                    <button className="navButton" onClick = {() => {props.nextPage()}}
                                            >
                                        NEXT
                                    </button>
                                    <IoIosArrowForward className='arrow 'onClick = {() => {props.nextPage()}}/>
                                </div>
                            </div>


                            <div className = 'directedBy'> {directedBy} </div>


                            
                            <div className = 'projectText'> {projectPara1} </div>
                            <div className = 'projectText'> {projectPara2} </div>
                            <div className = 'projectText'> {projectPara3} </div>

                            

                            <div className = "supplementaryImageContainer" >
                                <div className='frame'>
                                    <img src = {image2Url} className = "projectImage" />
                                </div>
                                <div className='frame'>
                                    <img src = {image3Url} className = "projectImage" />
                                </div>
                            </div>


                            <div className = "navigationButtons">





                                <div className='centreButtonWrapper'>
                                    <Link to='/Portfolio'>
                                    <button className="button4"
                                            >
                                        BACK TO PORTFOLIO
                                    </button>
                                    </Link>
                                </div>
                            </div>


                            <div className='solidLine'> _ </div>
                            
                        </div>





                        





                    </div>
                    
                </div>
                

            <Footer/>
        </div>
    )
}
export default ProjectPage; 