
import React from "react";
import './Footer.css'



const Footer = () => {
    return (
        <div className="footerWrapper">
            <div className = "footer">
                ©Amberlight Music. Powered By React
            </div>
        </div>

    )
}
export default Footer;