import { useState } from "react"
import {IoIosPause, IoIosPlay, IoIosSkipBackward, IoIosSkipForward} from 'react-icons/io'
import PlayerControlsCSS from './PlayerControls.module.css'

export const PlayerControls = ({isPlaying,updateState,stateFlag,prev,next}) => {

    const PlayPause = ()=>
    {
        isPlaying.current = !isPlaying.current;
        updateState(!stateFlag);
    }

    return (
        <div className = {PlayerControlsCSS.container}>
            
            <IoIosSkipBackward className={PlayerControlsCSS.skipBack} onClick={() => {prev()}}/>
            {isPlaying.current ? <IoIosPause className={PlayerControlsCSS.pause} onClick={PlayPause}/> : <IoIosPlay className={PlayerControlsCSS.play} onClick={PlayPause}/>}
            <IoIosSkipForward className={PlayerControlsCSS.skipForward} onClick={() => {next()}}/>
            
        </div>
    )
}