import React from "react";
// import videoBg from '../assets/OurPlanet.mp4'
import VideoWithOverlay from "./videoWithOverlay";
import './VideoBackground.css'

const VideoBackground = (props) => {

    const videoUrl = props.videoUrl
    // console.log(props)
    
    return (
        <div className ='main'>


            <div className = 'colourBackground '></div> 
            {/* <div className = 'overlay'></div> */}
            
            <div className="videoFrameMain">
                <VideoWithOverlay
                videoUrl = {videoUrl}
                />
            </div>
            
            {/* <div className="colourOverlay" /> */}
            

        </div>


    )
}

export default VideoBackground;