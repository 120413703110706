
import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation,Pagination,Scrollbar,A11y} from 'swiper';
import 'swiper/css';
import 'swiper/swiper-bundle.min.css'
import '../App.css';
import SliderCSS from './Carousel.module.css';
import portfolioSlideData from '../databases/portfolioSlideData.json'

import PortfolioSlide from './PortfolioSlide';

const Slider = () => {
    return(
        <Swiper className={SliderCSS.carousel} 


        modules={[Navigation]}
        spaceBetween = {0} 
        slidesPerView={1}
        breakpoints={{
            0: {
              slidesPerView: 1,
            },
            705:{
              slidesPerView:2,
            },
            870: {
              slidesPerView: 3,
            }
          }}
        navigation
        onSwiper={(swiper) => console.log(swiper)}
        >

            <div>
                {
                    portfolioSlideData.map(slideData => {
                        return (
                         <SwiperSlide key={slideData.index}>
                            <PortfolioSlide 
                            // title='MAMAS SEASON 2'
                            index = {slideData.index}
                            title={slideData.title}
                            imageURL = {slideData.carouselImage}
                            slideLink =  {slideData.slideLink}
                            ></PortfolioSlide>
                        </SwiperSlide>
                        )
                    })
                }
            </div>


        </Swiper>
    )

}

export default Slider