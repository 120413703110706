
import '../Backgrounds.css'
import './Contact.css';
import '../App.css';
import MainHeader from '../components/MainHeader';

import Footer from "../components/Footer";
import { useEffect } from 'react';


const About = () => {

    useEffect(() => {
        window.scrollTo(0,0)
      }, []);

    return (
        <div className='contactMeBackground'>
            
            <MainHeader/>
            <div className='mainContentWrapper'>
                <div className='contactMeContentWrapper'>

                    <div className='contactMeContent'>
                        {/* <div className='subheading1'>
                            CONTACT
                        </div>
                        <p></p><br></br> */}

                        <div className='lineHeading1'>
                            CONTACT
                        </div>
                        <p></p><br></br><div className='contactMeText'>will.turner@amberlightmusic.com</div>
                        <br></br><div className='contactMeText'>+44 (0)7585320127</div>
                        <br></br>
                        <div className='solidLine'></div>
                        <Footer/>
                    </div>
                </div>
                
            </div>
            
        </div>

    )
}
export default About; 
