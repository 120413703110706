import { initializeApp } from "firebase/app";
import { getStorage,ref } from "firebase/storage";
import {getFirestore} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDNzeLhL9W88zwk_NQ9EbP1Gh0hbJIVzFQ",
  authDomain: "firestoredatabasetest-c8bb0.firebaseapp.com",
  projectId: "firestoredatabasetest-c8bb0",
  storageBucket: "firestoredatabasetest-c8bb0.appspot.com",
  messagingSenderId: "689860332377",
  appId: "1:689860332377:web:859e7c114c54dcc3095a48",
  measurementId: "G-8P3YHTBE5W"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  
  const projectStorage = getStorage(app);
  const projectFirestore =  getFirestore(app);

  const timestamp = getFirestore(app).ServerTimestamp;
  
  
  //FieldValue.serverTimestamp;
  export {projectStorage,projectFirestore, timestamp};