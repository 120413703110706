import PortfolioSlideCSS from './PortfolioSlide.module.css'
import {Link,useParams} from 'react-router-dom'



const PortfolioSlide = (props) => {
    
    return (
                <div className={PortfolioSlideCSS.carouselBlock} >
                    <img src = {props.imageURL} className = {PortfolioSlideCSS.carouselImage} />
                            <Link to= {`/events/${props.index}`} params={{ projectID: "2" }}  className={PortfolioSlideCSS.carouselWrapper} >
                                        <div className={PortfolioSlideCSS.carouselTitle}>{props.title}</div>
                            </Link>
                    
                    
                </div>


    )
}

export default PortfolioSlide;