
import '../App.css';
import GalleryCSS from './Gallery.module.css';
import portfolioSlideData from '../databases/portfolioSlideData.json'
import PortfolioSlide from './PortfolioSlide';

const Gallery = () => {
    return(
        
        <div className={GalleryCSS.galleryWrapper}>

         {
                    portfolioSlideData.map(slideData => {
                        return (

                            
                            <div className={GalleryCSS.galleryItem} key={slideData.index}>
                                {/* {slideData.title} */}
                                <PortfolioSlide 
                                // title='MAMAS SEASON 2'
                                index = {slideData.index}
                                title={""}
                                imageURL = {slideData.imageURL}
                                slideLink =  {slideData.slideLink}
                                ></PortfolioSlide>
                            </div>
                        )
                    })
                }
            

        </div>


        // <Swiper className={SliderCSS.carousel} 


        // modules={[Navigation]}
        // spaceBetween = {0} 
        // slidesPerView={1}
        // breakpoints={{
        //     0: {
        //       slidesPerView: 1,
        //     },
        //     435:{
        //       slidesPerView:2,
        //     },
        //     870: {
        //       slidesPerView: 3,
        //     }
        //   }}
        // navigation
        // onSwiper={(swiper) => console.log(swiper)}
        // >

        //     <div>
        //         {
        //             portfolioSlideData.map(slideData => {
        //                 return (
        //                  <SwiperSlide key={slideData.index}>
        //                     <PortfolioSlide 
        //                     // title='MAMAS SEASON 2'
        //                     title={slideData.title}
        //                     imageURL = {slideData.imageURL}
        //                     slideLink =  {slideData.slideLink}
        //                     ></PortfolioSlide>
        //                 </SwiperSlide>
        //                 )
        //             })
        //         }
        //     </div>


        // </Swiper>
    )

}

export default Gallery