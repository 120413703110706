import PlayerItem from "./playerItem"
import GetTracks from "../../hooks/GetTracks"
import TrackListCSS from './TrackList.module.css'

import {IoIosPause, IoIosPlay} from 'react-icons/io'

export const TrackList = ({trackList,trackNumber,setTrackNumber,isPlaying,playPause,loadTrack}) => {


    const toggleTrack = (trackIndex) => {

        if((trackNumber == trackIndex) && (isPlaying.current))
        {
            playPause();
        } else
        {
            if ((trackNumber == trackIndex))
            {
                playPause();
            } else {
                // stop();
                isPlaying.current = true;
                loadTrack(trackIndex + 1);
                // setTrackNumber(trackIndex + 1);
                // setTrackNumber(trackIndex + 1);
                // playPause();
            }
        }
    }

    return(
        

    <div className = "trackList">
        {trackList.map((track) => {
            
            return (
                <div key={track.index} className = {TrackListCSS.trackContainer}>
                    {/* <div className="apPlayButton"> 
                        <button onClick = {() => {setCurrentTrack(track)}}>p</button>
                    </div> */}

                    <div>
                         {(trackNumber == track.index) && (isPlaying.current) ? 
                         <IoIosPause className='btn_action pp' onClick={() => playPause()}/> : 
                         <IoIosPlay className='btn_action pp' onClick={() => 
                         
                         
                            {

                                if(trackNumber == track.index) {


                                    playPause();
                                } else {
                                    // stop();
                                    isPlaying.current = true;
                                    // toggleTrack(track.index + 1);
                                    loadTrack(track.index + 1);
                                }
                            }
                         
                         
                         }/>}
                    </div>

                    <div onClick={() => toggleTrack(track.index)} className={(trackNumber == track.index && isPlaying.current) ? TrackListCSS.trackNumberSelected : TrackListCSS.trackNumber}> {track.index + 1} </div>
                    
                    <div className={TrackListCSS.trackNameWrapper }>
                    <div onClick={() => toggleTrack(track.index)} className={(trackNumber == track.index && isPlaying.current) ? TrackListCSS.trackNameSelected : TrackListCSS.trackName}> {track.trackName} 
                    <span className={(trackNumber == track.index && isPlaying.current) ? TrackListCSS.trackDescriptionSelected : TrackListCSS.trackDescription}>{track.trackDescription}</span> 
                    </div>
                    </div>
                    <div onClick={() => toggleTrack(track.index)} className={(trackNumber == track.index && isPlaying.current) ? TrackListCSS.trackLengthSelected : TrackListCSS.trackLength}> {new Date((track.trackLength) * 1000).toISOString().substring(14,19)} </div>
                </div>
            )
        })


    }
    </div>
  )
}