
import React from "react";
import MainHeaderCSS from './MainHeader.module.css'
import {GiHamburgerMenu} from 'react-icons/gi'
import {Link} from 'react-router-dom'



const MainHeader = () => {
    return (
        <div className ={MainHeaderCSS.mainHeader}>
        {/* <div className ={MainHeaderCSS.mainHeader}> */}
            <div className={MainHeaderCSS.webTitleContainer}>
                <Link to = '/'>

                    <div className={MainHeaderCSS.webTitle} >
                        WILL TURNER
                        
                    </div>
                </Link>
            </div>
            

        </div>


    )
}

export default MainHeader;