
import React, { useEffect, useState } from "react";
import logo from './logo.svg';
import './App.css';
import Main from "./components/videoBackground";
import Home from "./pages/Home"
import About from "./pages/About"
import AudioPlayer from "./components/audioPlayer/AudioPlayer";
import MainHeader from "./components/MainHeader";
import MenuDropdown from "./components/MenuDropdown";
import TestButton from "./components/MenuDropdown.js";
import {Routes, Route, useParams} from 'react-router-dom';
// import Projects from "./pages/ProjectPage";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import './Backgrounds.css'
import ProjectWrapper from "./pages/ProjectWrapper";
import PrivateProjectWrapper from "./pages/PrivateProjectWrapper";


function App() {

  // const projectId = 1;
 


  return (
    <div className="App">


      {/* <div> */}
        <Routes >
          <Route path= '/' element={<Home/>} />
          <Route path= '/about' element={<About/>} />
          <Route path= '/events/:projectID' element={<ProjectWrapper/> }  />
          <Route path= '/private/:projectID' element={<PrivateProjectWrapper/> }  />
          <Route path= '/portfolio' element={<Portfolio/>} />
          <Route path= '/contact' element={<Contact/>} />

        </Routes>
        <MenuDropdown/> 
      {/* </div> */}


    </div>
  );
}

export default App; 
