import { useState,useRef,useEffect } from "react";
import MenuDropdownCSS from './MenuDropdown.module.css';
import {GiHamburgerMenu} from 'react-icons/gi'
import OpenDropdown from "./OpenDropdown";
import {RxCross1} from 'react-icons/rx'
import './DropdownTransition.css';
import { CSSTransition } from 'react-transition-group';

// import './LoadingPage.css'
import PulseLoader from "react-spinners/PulseLoader";


const LoadingPage = ({loading}) => {


    // const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //   setLoading(true)
    //   setTimeout(() => {
    //     setLoading(false)
    //   }, 2000)
    // }, [])

    const nodeRef = useRef(null);
    return (


        
        <CSSTransition nodeRef={nodeRef} in={loading} timeout={900} classNames="loading" unmountOnExit>
            <div ref={nodeRef} className="loadingBackground">
                <PulseLoader size={50} color={'#d9dada'} loading={loading} />
            </div>
        </CSSTransition>
       

    //   <div>


        
    //     <CSSTransition nodeRef={nodeRef} in={dropdownIsOpen} timeout={200} classNames="my-node" unmountOnExit>
    //       <div ref={nodeRef}>
    //         <OpenDropdown openDropdown={openDropdown}/>
    //       </div>
    //     </CSSTransition>
    //   </div>
    );
}

export default LoadingPage;