import Wavesurfer from "wavesurfer.js";

import { useEffect, useRef, useState } from "react";
import { ref,uploadBytes,getDownloadURL,listAll } from "firebase/storage";
import { projectStorage, projectFirestore, timestamp } from '../../firebase/config';
import {TrackList} from "./TrackList";
import { PlayerControls } from "./PlayerControls";
import AudioPlayerCSS from './AudioPlayer.module.css'
import '../../App.css';
// import TrackArray from "../../databases/mainPageTracks.json";
// import peakbuilder from 'wavesurfer-peakbuilder'
// import { Flex, Button } from "@chakar-ui/react";

const AudioPlayer = (props) => {

  const isPlaying = useRef(false);
  const [stateFlag,updateState] = useState(false);
  const [refList, setRefList] = useState([]);
  const [currentTrack, setCurrentTrack] = useState(props.trackArray[0]);
  // const [trackList, setTrackList] = useState([]);
  const waveform = useRef(null);
  const [trackNumber,setTrackNumber] = useState(0);
  const [initialRender,setInitialRender] = useState(true);
  const [trackPosition,setTrackPosition] = useState(0);
  const [trackDruation, setTrackDuration] = useState(0);
  const [trackTriggered,setTrackTriggered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const isSafari = (/^((?!chrome|android).)*safari/i.test(navigator.userAgent || '') ||
      /iPad|iPhone|iPod/i.test(navigator.userAgent || ''));

  // const isIOS = (/iPad|iPhone|iPod/i.test(navigator.userAgent || ''))
  const isIOS = true;

  var context,processor; 
  

  const playPause = () =>
  {

      isPlaying.current = !isPlaying.current;
      updateState(!stateFlag);
  }
  const url = {};

  const stop = () =>
  {
    waveform.current.stop();
  }

  const prev = () => {
    ((trackNumber) < 1) ? 
    loadTrack(props.trackArray.length) :
    loadTrack(trackNumber) 

  }

  const next = () => {
    
    (((trackNumber+1) > (props.trackArray.length-1)) || props.trackArray.length == 1) ? 
    loadTrack(1) :
    loadTrack(trackNumber + 2) 
  
  }

  const loadTrack = (newTrack) =>
  {
    // if(trackNumber != currentTrack.index+1)
    // {

      

      console.log(newTrack);
      waveform.current.pause();
      setCurrentTrack(props.trackArray[newTrack-1]);
      props.onTrackChange(props.trackArray[newTrack-1]);
      setTrackNumber(newTrack-1);
      // setIsLoading(true);




       waveform.current.load(props.trackArray[newTrack-1].trackUrl,props.trackArray[newTrack-1].pcm,'auto');
      // waveform.current.load(props.trackArray[newTrack-1].trackUrl);
      


      setTrackDuration(props.trackArray[newTrack-1].trackLength)
      waveform.current.seekTo(0);
      
     
      
    // }
  }

  const waveSurferArgs = {
      container: "#waveform",
      waveColor: "#d9dada",
      progressColor: "#7bdcb5",
      barGap: 2,
      height : 100,
      barWidth: 1.5,
      barRadius: 3,
      cursorWidth: 0,
      cursorColor: "#d9dada", 
      responsive: true,
      hideScrollbar: true,
  }

  if(isIOS) {
    waveSurferArgs.backend = 'MediaElement'
  } 

  useEffect(() => {

    

    // Check if wavesurfer object is already created.
    if (!waveform.current) {
      waveform.current = Wavesurfer.create(waveSurferArgs);



      waveform.current.on('ready', () =>{

        if(!isIOS){
         if (isPlaying.current) waveform.current.play();
        }

        // Export PCM data once the waveform is ready
        console.log("ready")

  
      }

      )

      waveform.current.on('waveform-ready', () => {
        if(isIOS){
        if (isPlaying.current) waveform.current.play();



        // ***** EXPORTS PCM WHEN NEEDED
        // waveform.current.exportPCM()
        // .then(pcmData => {
  
        //   const jsonString = JSON.stringify(pcmData);
        //   console.log(jsonString); // The PCM data as a JSON string
        // })
        // .catch(error => {
        //   console.error('Error exporting PCM data:', error);
        // });
        
      }
        
        // waveform.current.exportPCM();
      })


      waveform.current.on('finish', () => {
        // console.log("trackNumber" + trackNumber);
        // next();
      })

      waveform.current.on('audioprocess',() => {
        // setIsLoading(false);
        setTrackPosition(Math.floor(waveform.current.getCurrentTime()))
      })
      // Load audio from a remote url.
      // const storageRef = ref(projectStorage, props.folderPath);


      
    }

    loadTrack(1);
    return () => waveform.current.destroy()
  }, []);

  

  // useEffect(()=>{
  //   if(trackNumber > 0) 
  //   {
  //     waveform.current.pause();


  //     if(isIOS) {
  //       waveform.current.load(currentTrack.trackUrl)//,currentTrack.pcm)
  //     } else {
  //       waveform.current.load(currentTrack.trackUrl,currentTrack.pcm)
  //     }

  //     if (isPlaying.current && isIOS) {
  //       // setTimeout(() => {waveform.current.play()}, 500);
  //       // setTrackDuration(Math.round(waveform.current.getDuration()))
  //       waveform.current.play();
  //       // waveform.current.seekTo(0);
  //     }
  //     // waveform.current.play();

  //     // getDownloadURL(currentTrack.reference).then((url) => {
  //     //   waveform.current.load(currentTrack.trackUrl)
  //     // })
  //   }
    
  // },[currentTrack])

  // useEffect(() => {
    

  //   if (initialRender)
  //   {
  //     setInitialRender(false);
  //   } else {

  //     if(trackNumber != currentTrack.index+1)
  //     {
  //       // waveform.current.pause();
  //       setCurrentTrack(props.trackArray[trackNumber-1]);
  //       props.onTrackChange(props.trackArray[trackNumber-1]);
  //     }
      
  //   }
  // },[trackNumber])



  useEffect(() => {
    if(isPlaying.current)
    {
      waveform.current.play();
    } else
    {
      waveform.current.pause();
    }

    // waveform.current.exportPCM(1024,10000,false)
  },[stateFlag])

  const loadAndPlayTrack = (reference) => {
    getDownloadURL(reference).then((url) => {
      waveform.current.load(currentTrack.trackUrl,props.trackArray[trackNumber-1].pcm);
    })
    
  }




  return (

      <div className={AudioPlayerCSS.audioPlayer}>

        {props.multiTrack == "true" ?   
            <div className={AudioPlayerCSS.trackListContainer}>
              <div className={AudioPlayerCSS.trackList}>
                
                
                
                
                <TrackList trackList = {props.trackArray} isPlaying={isPlaying} loadTrack = {loadTrack} playPause = {playPause} trackNumber={trackNumber}  setTrackNumber = {setTrackNumber}/>  
                
                

              </div>
            </div>
        : null}

        <div className ={AudioPlayerCSS.waveformParent}>

          <div className={AudioPlayerCSS.trackTitle}>
            <p className={AudioPlayerCSS.trackTitleText}>  {isLoading ? "loading..." : currentTrack.trackName}
            </p>
          </div>
          <div className ={AudioPlayerCSS.waveformContainer} >
            <div className ={AudioPlayerCSS.waveformPosition}>
               {new Date(trackPosition * 1000).toISOString().substring(14,19)} 
            </div>
            <div id = "waveform" className ={AudioPlayerCSS.waveform}> </div>
            <div className ={AudioPlayerCSS.waveformRemainingDuration}> {new Date((trackDruation - trackPosition) * 1000).toISOString().substring(14,19)} </div>
            </div>
            <div className ={AudioPlayerCSS.playerControls}>
              <PlayerControls updateState={updateState} isPlaying={isPlaying} prev = {prev}  next = {next} stateFlag = {stateFlag}/>
            </div>
          

        </div>
        
      </div>
  );
};

export default AudioPlayer;








