import OpenDropdownCSS from './OpenDropdown.module.css';
import {RxCross1} from 'react-icons/rx'
import {Link} from 'react-router-dom'


const OpenDropdown = ({openDropdown}) => {

    return (

        <div className={OpenDropdownCSS.openDropdown}>
            <div className={OpenDropdownCSS.dropdownMenu}>
            <Link to='/' className='dropdownItem' onClick ={()=>{openDropdown(false)}}>Home</Link>
            <Link to='/about' className='dropdownItem' onClick ={()=>{openDropdown(false)}}>About</Link>
            {/* <Link to='/Events' className='dropdownItem' onClick ={()=>{openDropdown(false)}}>Events</Link> */}
            <Link to='/Portfolio' className='dropdownItem' onClick ={()=>{openDropdown(false)}}>Portfolio</Link>
            <Link to='/contact' className='dropdownItem' onClick ={()=>{openDropdown(false)}}>Contact</Link>
            </div>
            <RxCross1 className={OpenDropdownCSS.closeButton} onClick ={()=>{openDropdown(false)}}/>
            
        </div>

        // <div className={OpenDropdownCSS.openDropdown}>

                
            
        // </div>
    )
}

export default OpenDropdown;